.action--games {
  a {
    padding: 0px !important;
    margin: 0px;

    img {
      margin: 0px;
    }
  }
}

.aspect-preserve--133-7473214285714 {
  padding-bottom: 133.7473214285714%;
}

.aspect-preserve--125 {
  padding-bottom: 125%;
}

.aspect-preserve--115-625 {
  padding-bottom: 115.625%;
}

.aspect-preserve--141-5598214285714 {
  padding-bottom: 141.5598214285714%;
}
